@import '../../../../assets/styles/utils/vars';
@import '../../../../assets/styles/base/icons';

.notification {
  position: relative;
  float: left;
  width: 100%;
  margin-bottom: 10px;
  padding: 20px 16px;
  background-color: $base-color;
  border: 1px solid $base-color;
  box-shadow: 0 0 16px rgba(113, 183, 144, 0.5);;
  border-radius: $border-radius;
  color: #fff;
  font-family: $base-font-family;
  cursor: pointer;
  transition: all 0.5s;

  &:before {
    @include font-setup();
    content: $icon-info;
    position: absolute;
    top: 50%;
    left: auto;
    right: 16px;
    transform: translateY(-50%);
    font-size: 24px;
  }

  &__title,
  &__content {
    margin: 0;
    padding: 0 40px 0 0;
  }

  &__title {
    font-size: 20px;
    line-height: 30px;
  }

  &__content {
    font-size: var(--font-size-sm);
    line-height: 16px;
  }

  &__loader {
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    height: 2px;

    span {
      float: left;
      height: 100%;
      background: #fff;
    }
  }

  &--success {
    background-color: var(--notification-success-color);
    border-color: var(--notification-success-color);
    box-shadow: 0 0 16px var(--notification-success-box-shadow-color);

    &:before {
      content: $icon-success;
    }
  }

  &--error {
    background-color: var(--notification-error-color);
    border-color: var(--notification-error-color);
    box-shadow: 0 0 16px var(--notification-error-box-shadow-color);

    &:before {
      content: $icon-error;
    }
  }

  &--alert {
    background-color: var(--notification-alert-color);
    border-color: var(--notification-alert-color);
    box-shadow: 0 0 16px var(--notification-alert-box-shadow-color);
  }

  &--info {
    background-color: $info-color;
    border-color: $info-color;
    box-shadow: 0 0 16px rgba(237, 194, 66, 0.5);
  }

  &--rtl-mode {
    direction: rtl;

    &:before {
      left: 16px;
      right: auto;
    }
  }

  &--rtl-mode &__title,
  &--rtl-mode &__content {
    padding: 0 0 0 40px;
  }
}
