.notifications {
  position: fixed;
  width: 300px;
  z-index: 1000;
}

.notifications.left {
  left: 20px;
}

.notifications.top {
  top: 20px;
}

.notifications.right {
  right: 20px;
}

.notifications.bottom {
  bottom: 20px;
}
