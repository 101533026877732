@import '../../../../assets/styles/utils/vars';

.not-found {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 120px 16px;

  &__container {
    align-items: center;
    justify-content: center;
    width: 560px;
    text-align: center;
  }

  &__logo {
    width: 270px;
    margin: 0 auto;
  }

  &__logo-image {
    width: 100%;

    * {
      fill: var(--primary-color);
    }
  }

  &__error {
    color: var(--primary-color);
    font-family: var(--header-font-family);
    font-size: 280px;
    font-weight: 600;
  }

  &__content {
    display: inline-block;
    width: 264px;
    border-top: 2px solid var(--primary-color);
  }

  &__info {
    margin-top: 24px;
    color: $base-color;
    font-family: var(--header-font-family);
    font-size: var(--base-font-size);
    font-weight: 400;
  }

  &__primary-action {
    margin-top: 48px;
  }

  &__secondary-action {
    display: inline-block;
    margin-top: 24px;
  }
}
